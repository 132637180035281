@import "../../assets/styles/variables.scss";
#productosPorCategoria {
  .topProducto {
    .back {
      margin-top: 10px;
      height: 18px;
      cursor: pointer;
    }
    .search {
      width: 350px;
    }
  }
}
.productoItem {
  overflow: hidden;
  border-radius: 12px;
  transition: ease-in-out all 0.2s;
  margin-bottom: 40px;
  box-shadow: 0 0 10px rgba(139, 158, 167, 0.1);
  width: 100%;
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  background: #fff;
  &:hover {
    box-shadow: 0 0 14px rgba(139, 158, 167, 0.2);
  }
  figure {
    cursor: pointer;
    margin-bottom: 0;
    img {
      width: 100%;
      display: block;
      border-radius: 0;
    }
  }
  .detalle {
    padding: 20px;
    padding-bottom: 0;
    .nombre {
      font-weight: bold;
      font-size: 14px;
      text-align: center;
    }
    .descripcion {
      text-align: center;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .precio {
      text-align: center;
      margin-bottom: 24px;
    }
  }
  .buttonAdd {
    font-size: 14px;
    width: 120px;
    height: 40px;
    line-height: 38px;
    color: $color6;
    font-weight: 600;
    text-align: center;
    border: solid 1px $color6;
    border-radius: 8px;
    background: #fff;
    margin: 0 auto;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    span {
      display: flex;
      line-height: 14px;
      height: 14px;
    }
  }
  .agotado {
    font-size: 14px;
    margin-top: auto;
    width: 120px;
    height: 40px;
    line-height: 38px;
    color: $color3;
    font-weight: 600;
    text-align: center;
    border: solid 1px #e5e5e5;
    border-radius: 8px;
    background: #f0f0f0;
    margin: 0 auto;
    cursor: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    span {
      display: flex;
      line-height: 14px;
      height: 14px;
    }
  }
  .buttonsCar {
    margin-top: auto;
    justify-content: center;
    align-items: center;
    i {
      height: 32px;
      width: 32px;
      font-size: 30px;
      font-weight:600;
      cursor: pointer;
      &:before {
        color: $color6;
      }
    }
    svg{
      fill:$color6;
      cursor: pointer;
    }
    span {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      height: 18px;
      margin: 0 10px;
    }
  }
}

.modalProd {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  overflow: auto;
  & > .wrap {
    padding: 30px;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    & > .content {
      .icon-cerrar {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 3000;
        cursor: pointer;
        font-size: 30px;
        padding: 5px;
        line-height: 31px;
        border-radius: 100%;
        background: #fff;
      }
      overflow: hidden;
      min-height: 100px;
      width: 100%;
      min-width: 300px;
      margin: 0px auto;
      max-width: 1100px;
      background: #fff;
      border-radius: 12px;
      position: relative;
      @media (max-width: 767px) {
        max-width: 430px;
      }
      .image {
        overflow: hidden;
        max-height: 100%;
        position: relative;
        max-width: 430px;
        figure.ppal {
          display: flex;
          align-items: center;
          align-content: center;
          // background: url(../images/placeholderImg.svg) no-repeat;
          background-size: cover;
          background-position: center center;
          // min-height: 700px;
          img {
            width: 100%;
            border-radius: 0;
            max-height: 700px;
            object-fit: cover;
            max-width: 100%;
            object-position: center;
            height: 700px;
            width: 430px;
            @media (max-width: 700px) {
              max-height: 500px;
            }
          }
        }
        .thumbnails{
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 80px;
          padding: 5px;
          figure{
            margin-bottom: 10px;
            box-shadow: $shadow;
            cursor: pointer;
            padding:1px;
            border-radius: 4px;
            background: #FFFFFF;
            img{
              display: block;
              width: 100%;
              border-radius: 4px;
            }
          }
        }
      }
      .detalle {
        overflow: hidden;
        position: relative;
        .header {
          height: 150px;
          padding: 35px 25px;
          border-bottom: solid 1px $placeholder;
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          p {
            
            font-size: 14px;
            margin-bottom: 0;
          }
        }
        .opciones {
          overflow: auto;
          margin-top: 150px;
          margin-bottom: 70px;
          max-height: 100%;
          max-height: calc(100% - 220px);
          max-height: 400px;
          .customOption {
            padding: 25px;
            border-bottom: solid 1px $placeholder;
            .top {
              .textSubTitle.errorV{
                color: $text-error;
              }
              h4 {
                margin-bottom: 20px;
                font-size: inherit;
                font-family: inherit;
              }
            }
            .bodyOptions {
              .option {
                label {
                  font-style: 16px;
                  
                }
                .precio {
                  font-style: 16px;
                  font-weight: 600;
                }
              }
            }
          }
          .errorVar{
            font-size: 12px;
            color: $text-error;
          }
        }
        .footer {
          height: 70px;
          position: absolute;
          bottom: 0;
          background: #fff;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: auto;
          padding: 5px 20px;
          width: 100%;
          .buttonsModal {
            display: flex;
            margin-right: 10px;
            i {
              font-size: 32px;
              height: 32px;
              line-height: 32px;
              font-weight: 600;
              cursor: pointer;
              &:before {
                color: $color6;
              }
            }
            svg{
              fill: $color6;
              cursor: pointer;
            }
            span {
              font-size: 18px;
              font-weight: bold;
              margin: 0 10px;
            }
          }
          button.buttonAdd {
            font-size: 14px;
            width: 120px;
            height: 40px;
            line-height: 38px;
            color: #fff;
            font-weight: 600;
            text-align: center;
            border: solid 1px $color6;
            border-radius: 8px;
            background: $color6;
            display: block;
            &.Eliminar{
              border-color:$text-error;
              background:$text-error;
            }
          }
          button.action {
            background: #6f706f;
            display: block;
            width: 100%;
            border: none;
            max-width: 260px;
            margin: 0 auto;
            height: 40px;
            line-height: 40px;
            padding: 0 10px;
            border-radius: 8px;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
          }
          .monto {
            font-size: 18px;
            font-weight: bold;
            margin-left: auto;
          }
        }
      }
    }
  }
}


.theme--dark {
  .productoItem{
    background: #FFF;
  }
}