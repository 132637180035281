.inputCode{
    display: flex;
    width: 100%;
    justify-content: center;
    .singleCode{
        font-size: 20px;
        height: 50px;
        max-width: 50px;
        line-height: 46px;
        border-radius: 4px;
        border:solid 1px #E5E5E5;
        padding:0 10px;
        text-align: center;
        flex: 1;
        margin-right: 5px !important;
        outline: none;
        &:last-child{
            margin-right: 0 !important;
        }
    }
}