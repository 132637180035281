@import '../../assets/styles/variables.scss';
#headerPpal{
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 900;
    background: #fff;
    border-bottom: solid 1px $placeholder;
    .logo-header{
        display: block;
        align-self: flex-start;
        img{
            display: block;
            width: auto;
            max-height: 60px;
        }
    }
    .links{
        a.linkGen{
            font-size: 14px;
            margin: 0 20px;
            line-height: 14px;
            cursor: pointer;
        }
    }
    
    .widgetTopOptions{
        position: relative;
        &>a{
            i{
                font-size: 12px;
                line-height: 8px;
                height: 8px;
                margin-top: 2px;
            }
        }
        .overlay{
            position: fixed;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 1;
        }
        ul{
            padding:0px 0px;
            border-radius: 12px;
            background: #fff;
            box-shadow:$shadow;
            width: 200px;
            position: absolute;top: 100%;
            list-style: none;
            margin-top: 0px;
            right: 0;
            z-index: 2;
            overflow: hidden;
            transition: ease-in-out all .2s;
            opacity: 0;
            &.visible{
                opacity: 1;
                margin-top: 20px;
            }
            li{
                p{
                    padding: 10px 20px;
                    cursor: pointer;
                    font-size: 14px;
                    margin-bottom: 0;
                    &:hover{
                        background: $placeholder;
                    }
                }
            }
        }
    }
}
#headerConfirm{
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #fff;
    border-bottom: solid 1px $placeholder;
    .notice{
        text-align: center;
        h3{
            font-size: 40px;
            font-weight: bold;
            color: var(--text-color);
        }
        span{
            font-size: 15px;
        }
        p{
            font-size: 18px;
            font-weight: bold;
        }
    }
}
.cintilloAbierto{
    width:100%;
    background:$placeholder;
    position: sticky;
    top: 80px;
    z-index: 800;
    p{
        padding: 10px 0;
        margin-bottom: 0;
        width: 100%;
        text-align: center;
    }
}

#detailCarTop{
    section{
        flex-grow: 1;
        overflow: hidden;
        display: flex;
        width: 100%;
        .ps{width: 100%;}
        .PerfectScrollbar{
            width: 100%;
        }
        .wrapItem{
            width: 100%;
            .item{
                padding:20px 0;
                border-bottom: solid 1px $placeholder;
                align-items: stretch;
                color: var(--c4);
                figure{
                    img{
                        display: block;
                        width: 48px;
                        height: 48px;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 6px;
                    }
                    margin-bottom: 0;
                }
                .det{
                    margin:0 15px;
                    display: flex;
                    flex-direction: column;
                    align-items:space-between;
                    justify-content: space-between;
                    .nombre{
                        font-size: 14px;
                        
                    }
                    .buttons{
                        align-items: center;
                        i{
                            font-size: 18px;
                            line-height: 18px;
                            height: 18px;
                            cursor: pointer;
                        }
                        svg{
                            fill:$color6
                        }
                        span{
                            font-size: 12px;
                            line-height: 12px;
                            font-weight: bold;
                            margin:0 5px
                        }
                    }
                }
                .price{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    margin-left: auto;
                    i{
                        height: 18px;
                        margin-left: auto;
                        cursor: pointer;
                        margin-top: 4px;
                        position: absolute; 
                        right: 8px;
                        color: rgb(153, 153, 153);
                    }
                    .monto{
                        font-size: 14px;
                        
                    }
                    .totalVariante{
                        font-size: 11px;
                        font-weight: bold;
                        white-space: nowrap;
                    }
                }
            }
        }
        .ps__rail-y{
            right: 0 !important;
            left: auto !important;
        }
    }
}