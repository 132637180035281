body{background: #fff !important;}

.multiplesFunctions{
  display:flex;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-left: 10px;
}
.multiplesFunctions .link-to-fun{
  display: block;
  padding: 5px 10px;
  border:solid 1px rgb(214, 214, 214);
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
  color: var(--c4);
  margin-bottom: 20px;
  border-radius: 100px;
}
.multiplesFunctions .link-to-fun.active{
  background:var(--c4);
  color: #fff;
  border:solid 1px var(--c4);
  cursor:default;
}

.swal2-popup.modalFreeAss{
  width: auto;
  max-width: 1000px;
}
.swal2-popup.modalFreeAss #wrap-ppal{
  margin-top: 20px;
}
.swal2-popup.modalFreeAss #wrap-ppal .container{
  width: 100%;
}
.swal2-popup.modalFreeAss .swal2-close{
  outline: none !important;
  font-size: 40px;
}
.swal2-popup.modalFreeAss #wrap-ppal .btn-get-tk{
  margin-bottom: 30px;
}