@import '../../assets/styles/variables.scss';
#listaCategorias{
    padding-top: 70px;
    padding-bottom: 70px;
    .gridCategorias{
        border-bottom: solid 1px $placeholder;
        padding-bottom: 40px;
        .categoriaItem{
            margin-bottom: 30px;
            text-align: center;
            cursor: pointer;
            figure{
                margin-bottom: 20px;
                img{
                    display: block;
                    margin: 0 auto;
                    border-radius: 12px;
                    box-shadow: 0 0 6px rgba(139,158,167,.16);
                    transition: ease-in-out all .2s;
                    max-width: 100%;
                }
            }
            &:hover{
                img{
                    box-shadow: 0 0 8px rgba(0,0,0,.15);
                }
            }
            .nombre{
                line-height: 20px;
                margin-bottom: 10px;
            }
        }
    }
    .categoriaDestacada{
        margin-top: 70px;
        h2{
            line-height: 20px;
            margin-bottom: 10px;
        }
        figure{
            img{
                display: block;
                max-width: 100%;
                border-radius: 12px;
            }
        }
        h4{
            font-size: 16px;
            margin-bottom: 20px;
        }
        p{
            text-align: justify;
        }
    }
}

.swiper-lazy-preloader {
    border-color:$color6 !important;
    border-width: 1px;
}

.css-gyjwul-EventSourceWrapper{
    border-width: 4px !important;
}

.atrributes-extras{
    padding: 30px 30px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 30px;
    display: grid;
    gap: 30px 10px;
    grid-template-columns: 33.333% 33.333% 33.333%;
    @media (max-width:1360px) {
        grid-template-columns: 50% 50%;
    }
    @media (max-width:640px) {
        grid-template-columns: 100%;
    }
    .attr-container{
        display: flex;
        align-items: center;
        img{
            display: block;
            margin-right: 12px;
        }
        p{
            color: var(--c4);
            font-size: 18px;
            span{
                display: block;
                margin-top: 5px;
                font-size: 14px;
                color: var(--c5);
            }
        }
    }
  }

  .additional-info{
    padding: 30px 20px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 30px;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        white-space: pre-line;
    .additional-container{
        padding-bottom: 20px;
        &:not(:last-child){
            margin-bottom: 20px;
            border-bottom: solid 1px #E4E8ED;
        }
        h3{
            font-weight: 700;
            font-size: 20px;
            color: var(--c4);
            
            display: flex;
            justify-content: space-between;
            align-items: center;
            button{
                background: none;
                padding: 0;
                border: none;
                cursor: pointer;
                svg{
                    transform: rotate(-180deg);
                }
            }
        }
        div.content-add{
            max-height: 0;
            overflow: hidden;
            color: var(--c5);
            // transition: ease-in-out all .3s;
            ul, ol{
                padding-left: 20px;
                li, ol{
                    margin-bottom: 10px;
                }
            }
        }
        &.active{
            div.content-add{
                max-height: 1000px;
            }
            h3{
                margin: 0 0 20px 0;
            }
            h3 button svg{
                transform: rotate(0deg);
            }
        }
    }
  }


  .info-importante{
      margin-top: 30px;
      width: 100%;
    .item-container{
        padding: 30px 20px;
        background-color: #fff;
        border-radius: 8px;
        border: solid 1px #e4e8ed;
        display: flex;
        align-items: center;
        color: var(--c5);
        img{
            display: block;
            margin-right: 20px;
            max-width: 60px;
        }
        h2{
            margin-top: 0;
            font-weight: 700;
            font-size: 18px;
            color: #353B47;
            margin-bottom: 5;
        }
        p{}
    }
  }