@import '../../assets/styles/variables.scss';
#footerPpal{
    min-height: 60px;
    background: $color1;
    color:#FFF;
    .redes{
        a{
            color:#FFF;
            margin-right: 15px;
        }
    }    
}