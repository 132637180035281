.swPopupCus{
  width:100% !important;
  max-width:320px !important;
  .swal2-content{
    padding-left: 0 !important;
    padding-right: 0 !important;
    .modalEntradas{
      h1{
        font-family: var(--font);
        margin-top: 0;
        margin-bottom: 20px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
      }
      .entradas{
        .entrada{
          border-bottom: solid 1px #e6e6e6;
          position: relative;
          cursor:pointer;
          &:hover{
            background: #f9f9f9;
          }
          p{
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 500;
            text-transform:uppercase;
            line-height: 14px;
            padding: 15px 40px 15px 10px;
            text-align: left;
          }
          span{
            position: absolute;
            right: 10px;
            top: 50%;
            padding:2px;
            transform: translateY(-50%);
            width: 16px;
            height:16px;
            border-radius: 50%;
            border: solid 1px #c3c3c3;
            &.active::before{
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              position: relative;
              background: var(--c1) !important;
            }
          }
          &:last-child{
            border-bottom: none;
          }
        }
      }
    }
  }
}
