.__react_component_tooltip.type-light {
   box-shadow: 0 0 5px rgba(0,0,0,0.3);
}

.__react_component_tooltip {
  padding: 10px;
}


.tooltip-container {

}

.tooltip-header{
  display: flex;
  margin-bottom: 5px;
}

.tooltip-header span {
  flex: 2;
  font-size: 10px;
  padding: 0 5px;
}

.tooltip-header span:first-child{
  flex: 3;
}

.tooltip-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.tooltip-content span {
  flex: 2;
  font-size: 12px;
  line-height: 1.33;
  color: #838383;
  padding: 0 5px;
}

.tooltip-content span:first-child{
  flex: 3;
}


.donacion{
  margin-left: 7px;
}