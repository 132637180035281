$color1:#000000;
$color2:#6F706F;
$color3:#BEBEBE;
$color4:#ADADAD;
$color5:#AAAAAA;
$color6:var(--c1);
$color7:#DEDDDD;
$placeholder:#F0F0F0;
$shadow:0 0 10px rgba(139,158,167,.16);
$bg_ppal:#F0F0F0;
$text-error:#ff0000;

$colorIniciado:#7254EF;
$colorDespachado:#0BABFF;
$colorDestino:#58E572; 