.titlePe {
  font-size: 22px;
  font-weight: bold;
  margin: 20px 0;
}
.wrapPerfilPedidos {
  max-width: 560px;
  margin: 0 auto 50px;
  .wrapTab {
    display: flex;
    width: 100%;
    padding: 6px 8px;
    border-radius: 8px;
    background: #f5f5f5;
    flex-grow: 1;
    margin-bottom: 30px;
    .optionTab {
      flex-grow: 1;
      border-radius: 6px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 16px;
      padding: 8px 5px;
      text-align: center;
      font-size: 12px;
      color: $color3;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 10px;
      }
      transition: ease-in all 0.2s;
      &.selected {
        background: #fff;
        box-shadow: $shadow;
        color: $color1;
      }
    }
  }
  .cardGen {
    border-radius: 12px;
    box-shadow: $shadow;
    background-attachment: #fff;
    padding: 20px 25px;
    .content {
      width: 100%;
      h3 {
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
      }
      p {
        font-size: 14;
        
        margin-bottom: 0px;
        color: $color3;
      }
      .estado {
        font-size: 14px;
        font-weight: 600;
        margin-left: 10px;
        padding-left: 15px;
        position: relative;
        line-height: 14px;
        &::before {
          content: "";
          display: block;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 3px;
          background: $color4;
        }
      }
    }
    &.iniciado {
      .estado {
        color: $colorIniciado;
        &::before {
          background: $colorIniciado;
        }
      }
    }
    &.despachado {
      .estado {
        color: $colorDespachado;
        &::before {
          background: $colorDespachado;
        }
      }
    }
    &.entregado {
      .estado {
        color: $colorDestino;
        &::before {
          background: $colorDestino;
        }
      }
    }
    &.desconocido {
      .estado {
        color: $color7;
        &::before {
          background: $color7;
        }
      }
    }
    .optionsCard {
      width: 40px;
      a {
        font-size: 12px;
        text-decoration: underline;
        text-decoration: none;
        cursor: pointer;
        i.icon-angle-left {
          display: block;
          transform: rotateY(-180deg);
          font-size: 18px;
        }
      }
    }
  }
  .addDireccion {
    display: block;
    margin: 30px auto;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    color: $color3;
    &:hover {
      color: $color1;
    }
  }

  .listProducts {
    .itemOrder {
      padding: 15px 0;
      border-bottom: solid 1px $placeholder;
      figure {
        margin-right: 10px;
        img {
          display: block;
          width: 48px;
          border-radius: 6px;
        }
        margin-bottom: 0;
      }
      .nombre {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 12px;
        color: $color1;
      }
      .descripcion {
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        color: $color3;
      }
      .icon-trash {
        cursor: pointer;
      }
      .controls {
        i {
          font-size: 18px;
          line-height: 18px;
          height: 18px;
          cursor: pointer;
        }
        span {
          line-height: 18px;
          font-size: 14px;
          font-weight: 600;
          padding: 0 10px;
        }
      }
      & > div {
        &:first-child {
          width: 60%;
          display: flex;
          align-items: center;
        }
        &:last-child {
          width: 40%;
        }
      }
    }
  }
}
