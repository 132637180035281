@import '../../assets/styles/variables.scss';
#bannerHome{
    height: 420px;
    .swiper-container{
        height: 100%;
        background: $bg_ppal;
        .swiper-slide{
            align-items: center;
            justify-content: center;
            align-content: center;
            background-size: cover;
            background-position: center center;
        }
    }
    .swiper-pagination-bullet{
        background: rgba(255,255,255,1);
    }
}