#mapContainer {
  padding: 0 0px;
  vertical-align: top;
  width: 100%;
  height: 241px;
  margin: 30px 0 10px;
  border-radius: 5px;
  overflow: hidden;
}
#mapContainer div:first-child {
  position: relative !important;
}
