@import './variables.scss';
._wrapinput{
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    input[type=text],.input[type=password],.inputControl,select{
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        display: block;
        width: 100%;
        border-radius: 8px;
        padding: 0 10px;
        border: solid 1px #bebebe;
        font-family: 'Open Sans', sans-serif;
        color: $color2;
        outline: none !important;
        &::placeholder{color:#bebebe;}
        &:-moz-placeholder{color: #bebebe;}
        &::-webkit-input-placeholder{color: #bebebe;}
    }
    &.select{
        select{
            appearance: none;
            padding-right: 40px;
        }
        i{
            position: absolute;
            right: 10px;
            top: 12px;
        }
    }
    &.icon-l{
        input{
            padding-left: 40px;
        }
        i{
            position: absolute;
            top:50%;
            left: 10px;
            color: #bebebe;
            transform: translateY(-50%);
        }
    }
    .error{
        position: absolute;
        margin-top: 0px;
        font-size: 11px;
        color: $text-error;
        top: 100%;
        left: 0;
    }
}

.radioCustomWrap{
    position: relative;
    overflow: hidden;
    input[type=radio]{
        display: block;
        opacity: 0;
        left: -2000px;
        position: absolute;
        &+label{
            position: relative;
            padding-left: 30px;
            cursor: pointer;
            &::before{
                content: "";
                display: block;
                width: 17px;
                height: 17px;
                border-radius: 50%;
                border:solid 1px $color7;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &:checked+label{
            &::after{
                content: "";
                display: block;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                background: $color6;
                position: absolute;
                left: 3px;
                top: 50%;
                transform: translateY(-50%);  
            }
        }
    }
}
.chekboxCustomWrap{
    position: relative;
    overflow: hidden;
    height: 22px;
    input[type=checkbox]{
        display: block;
        opacity: 0;
        left: -2000px;
        position: absolute;
        &+label{
            position: relative;
            padding-left: 30px;
            min-height: 20px;
            margin-bottom: 0;
            cursor: pointer;
            &::before{
                content: "";
                display: block;
                width: 19px;
                height: 19px;
                border-radius: 4px;
                border:solid 1px $color7;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &:checked+label{
            &::after{
                content: "";
                display: block;
                width: 11px;
                height: 11px;
                border-radius: 2px;
                background: $color6;
                position: absolute;
                left: 4px;
                top: 50%;
                transform: translateY(-50%);  
            }
        }
    }
}