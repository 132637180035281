@import '../../assets/styles/variables.scss';

#pageConfirm {
    padding: 30px 0;
    .title{
      font-size: 18px;
      font-weight: bold;
    }
    .infoComprador {
        .cobroDestino {
            display: inline-block;
            border-radius: 8px;
            height: 40px;
            line-height: 40px;
            background: #f7f7f7;
            color: $color1;
            font-size: 14px;
            padding: 0 20px;
            margin: 10px 0;
        }
        .direccion {
            p {
                margin-bottom: 5px;
            }
        }
    }
    .infoProductos {
        margin-bottom: 50px;

        .contacto {
            padding: 30px 0;
            border-bottom: solid 1px $placeholder;
            font-size: 14px;
            p {
                margin-bottom: 0;
                font-weight: bold;
            }
        }
        .productos {
            margin-bottom: 30px;
            box-shadow: $shadow;
            border-radius: 8px;
            overflow: hidden;
            margin-top: 30px;
            .title-table {
                display: flex;
                background: #fff;
                color: var(--color__text__light);
                span{
                  display: block;
                  width: 100%;
                  padding:10px 20px;
                  &:first-child{
                    min-width: 120px;
                    width: auto;
                  }
                  &:nth-child(2){
                    text-align: left;
                  }
                  &:last-child{
                    text-align: right;
                  }
                }
            }
            .itemCar {
                border-bottom: solid 1px $placeholder;
                overflow: hidden;
                padding: 10px;
                background: #fff;
                width: 100%;
                font-size: 18px;
                & > div:first-child {
                    width: 100%;
                    figure {
                        margin-right: 30px;
                        img {
                            display: block;
                            max-width: 100px;
                        }
                    }
                }
                & > div:last-child {
                    padding-left: 30px;
                    min-width: 100px;
                    text-align: center !important;
                    justify-content: center;
                }
            }
        }

      }
      .funcion{
        background: #fff;
        box-shadow: $shadow;
        border-radius: 8px;
        overflow: hidden;
        padding: 20px 40px;
        margin-top: 30px;
        figure{
          img{
            display: block;
            max-width: 100%;
            border-radius: 8px;
          }
        }
      }
      .nota{
        background: #fff;
        box-shadow: $shadow;
        border-radius: 8px;
        overflow: hidden;
        padding: 20px 40px;
        margin-top: 30px;
        p{
          font-size: 15px;
          font-weight: 50;
        }
      }
      .address{
        background: #fff;
        box-shadow: $shadow;
        border-radius: 8px;
        overflow: hidden;
        padding: 20px 0px;
        margin-top: 30px;
        p{
          padding: 0 20px;
          color: var(--color__text__light);
          font-size: 14px;
        }
        iframe{border-radius: 0 !important;}
      }
}
