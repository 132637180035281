@import "../../assets/styles/variables.scss";
//body{overflow: hidden;}
.modalAuth {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 1000;
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: -1;
  }
  .wrapAuth {
    padding: 10px 0;
    position: relative;
    min-height: 100%;
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .contentAuth {
      width: 100%;
      min-height: 420px;
      border-radius: 5px;
      background: #fff;
      padding: 50px 75px;
      position: relative;
      display: flex;
      align-items: stretch;
      .icon-cerrar {
        position: absolute;
        right: 25px;
        top: 25px;
        cursor: pointer;
      }
      h2 {
        text-align: center;
        margin-bottom: 40px;
      }
      label {
      }
      button.disabled {
        background: #f8f8fa;
        color: $color1 !important;
      }

      .codeComponent {
        p {
          margin-bottom: 5px;
          text-align: center;
        }
        .inputCode {
          margin-top: 30px;
        }
      }
      .registerComponent {
        .textTerminos {
          margin-bottom: 0;
          font-size: 12px;
          line-height: 16px;
          span {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}
